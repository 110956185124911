import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, HashRouter, Route, Switch } from "react-router-dom";
import HomeV9 from './home-v9';


class Root extends Component {
    render() {
                
                return (
                
                <HashRouter basename="/">
	                <div>
	                <Switch>
	                    
                        <Route exact path="/" component={HomeV9} />
                        
	                </Switch>
	                </div>
                </HashRouter>
                )
    
}}

export default Root;

ReactDOM.render(<Root />, document.getElementById('quarter'));
