import React from 'react';

import Navbar from './components/global-components/navbar';
import BannerV8 from './components/section-components/banner-v8';
import Footer from './components/global-components/footer';


const HomeV9 = () => {
    return <div>
         
        <Navbar/> 
        <BannerV8 />
        <Footer />
    </div>
}

export default HomeV9

